.ProfileCard {
    background: #ededed;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 16px 12px;

    &__photo {
        flex-shrink: 0;
        height: 56px;
        width: 56px;
        margin-right: 12px;
    }

    &__body {
        strong {
            font-weight: 700;
        }

        p {
            line-height: 1.2rem;

            &:first-child {
                text-transform: capitalize;
            }
        }
    }
}
