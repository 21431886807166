.Home {
    &__hero {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 256px;

        &__labels {
            display: flex;
        }
    }
}
