.CategoryLabel {
    display: inline-flex;
    margin: 6px;
    align-items: center;

    &__bullet {
        height: 16px;
        width: 16px;
        background: black;
        border-radius: 50%;
        margin-right: 4px;

        &--R {
            background: red;
        }

        &--O {
            background: orange;
        }

        &--G {
            background: lightgreen;
        }

        &--B {
            background: blue;
        }
    }

    &__text {}

    &--tiny {
        margin: 0;
        margin-left: 4px;

        .CategoryLabel__bullet {
            margin: 0;
            height: 10px;
            width: 10px;
        }
    }
}
