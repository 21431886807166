.AvatarGraph {
    height: 56px;
    width: 56px;
    margin-right: 12px;
    position: relative;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
