.Calculator {
    width: 100%;
    max-width: 512px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 12px;

    &__expression {
        margin-top: auto;
        font-weight: 500;
    }

    &__value {
        font-size: 6.5rem;
        line-height: 6.5rem;
        font-weight: 600;
        margin-bottom: 24px;
    }

    &__body {}

    &__button {
        //TODO: this is to be made into a styled component
        position: absolute !important;
        z-index: 1;
        height: calc(100% - 16px);
        width: calc(100% - 16px);
        top: 8px;
        left: 8px;
        background: darken(white, 6%) !important;
        border-radius: 50% !important;
        font-family: Montserrat;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 600;

        svg {
            height: 1.8rem;
            width: 1.8rem;
        }

        &__wrapper {
            position: relative;

            &::before {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }

        &--action {
            background: darken(white, 15%);
        }

        &--submit {
            //TODO: this is to be made into a styled component
            background: #0072ff !important;

            svg {
                fill: white;
            }
        }
    }
}
