.Value {
    font-weight: 500;
    font-size: 1.8em;
    line-height: 1.8rem;

    small {
        font-size: 50%;
    }

    strong {
        font-weight: 700;
    }

    span {
        font-size: 60%;

        &::before {
            content: ',';
            margin-right: 1px;
        }
    }

    &--huge {
        small {
            font-size: 3rem;
            font-weight: 400;
        }

        strong {
            font-size: 6rem;
            font-weight: 600;
        }

        font-size: 4rem;
        font-weight: 500;
        line-height: 8rem;
    }

    &--big {
        small {
            font-size: 4rem;
            font-weight: 400;
        }

        font-size: 4rem;
        line-height: 4rem;
    }

    &--tiny {
        small {
            font-size: .8rem;
            font-weight: 500;
        }

        strong {
            font-weight: 500;
        }

        font-size: .8rem;
        line-height: .2rem;
        font-weight: 400;
    }
}
