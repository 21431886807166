.DialogToolbar {
    background: transparent !important;
    box-shadow: none !important;

    &>nav {
        justify-content: flex-end;
    }

    button:first-child {
        margin-right: auto;
    }
}
