.AppLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__body {
        flex-grow: 1;
        overflow: auto;
        padding-bottom: 80px;
    }
}
