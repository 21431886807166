.Navbar {
    // Switch to styled
    box-shadow: none !important;
    background: #fafafa !important;

    &__left {}

    &__center {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 20px;
        }
    }

    &__right {
        text-align: right;

        img {
            height: 16px;
        }
    }
}
