.DebtCard {
    position: relative;
    padding: 16px 12px;
    box-sizing: border-box;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 24px -16px rgba(0, 0, 0, .2);
    min-height: 88px;

    &__avatar {
        margin-right: 8px;

        img {
            height: 48px;
            width: 48px;
        }
    }

    &__content {
        h5 {
            font-size: .9rem;
            line-height: 1rem;
            font-weight: 700;
        }

        h6 {
            font-size: .6rem;
            font-weight: 600;
            text-transform: capitalize;
            opacity: .6;
        }
    }

    &__value {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__labels {
        position: absolute;
        bottom: 4px;
        right: 8px;
    }
}
