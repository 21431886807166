.DebtViewer {
    padding: 0 16px;

    &__hero {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 304px;

        .Value {
            margin-bottom: 16px;
        }
    }
}
