.PlainTab {
    height: 56px;
    min-width: 80px;
    line-height: 56px;
    opacity: .4;
    transition: .2s;
    cursor: pointer;
    text-align: center;
    outline: none;

    &:hover {
        opacity: .6;
    }

    &:focus {
        opacity: 1;
    }

    &--active {
        font-weight: 700;
        opacity: .8;

        &:hover {
            opacity: 1;
        }
    }
}
