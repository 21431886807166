.BottomNav {
    &__item {
        &__label {
            font-weight: 700;
            font-size: .6rem !important;
        }

        &--selected {
            .BottomNav__icon {
                #primary {
                    fill: #0072FF;
                    z-index: 2;
                }

                #secondary {
                    fill: #00CAFF;
                    fill-opacity: .8;
                    z-index: 0;
                    transform: scale(1);
                    transform-origin: center;
                }
            }
        }
    }

    &__icon {
        height: 24px;

        * {
            mix-blend-mode: multiply;
        }

        #secondary {
            transform: scale(.6);
            transform-origin: center;
            transition: .3s;
        }
    }
}
